import React from 'react'
import { Grid, Typography, useMediaQuery } from '@mui/material'
import theme from '@flock/shared-ui/src/theme/theme'
import { DEFAULT_FUND_EQUITY_PERCENT_TARGET } from '@flock/utils'
import SectionLayout from '../components/SharedComponents/SectionLayout'
import PageWrapper from '../components/SharedComponents/PageWrapper'
import RentalCalculatorSection from '../components/RentalPropertyCalculatorComponents/RentalPropertyCalculatorContent'
import InlineMessageAddress from '../components/SharedComponents/InlineMessageAddress'
import {
  useRecordPageDuration,
  useScrollDepthTracker,
} from '../components/utils'
import { WebinarModal } from '../components/SharedComponents/WebinarModal'

const pageTitle = 'Flock | Sell vs Flock Calculator'
const pageDescription = `See how much you can make with Flock.`

export const Head = () => (
  <>
    <title>{pageTitle}</title>
    <meta title="title" property="og:title" content={pageTitle} />
    <meta
      name="description"
      property="og:description"
      content={pageDescription}
    />
  </>
)

const RENTALPROPERTYCALCULATOR = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))
  useRecordPageDuration()
  useScrollDepthTracker()

  return (
    <PageWrapper
      title={pageTitle}
      trackingName="rental-calculator"
      headerBackground="trustBlue.main"
    >
      <SectionLayout name="hero-section" backgroundColor="trustBlue.main">
        <Grid
          item
          xs={12}
          alignItems="center"
          display="flex"
          flexDirection="column"
          paddingBottom="0px"
        >
          <Typography
            variant="h1"
            sx={{
              paddingBottom: '0rem',
              textAlign: isMobile ? 'center' : 'unset',
            }}
          >
            Sell vs Flock
          </Typography>
        </Grid>
      </SectionLayout>
      <RentalCalculatorSection />
      <SectionLayout name="hero-section" backgroundColor="trustBlue.main">
        <Grid item xs={12} sx={{ marginTop: '300px' }}>
          <InlineMessageAddress
            text="Landlords, retire and minimize taxes."
            variant={isTablet ? 'button' : 'address'}
            label="Get Started"
          />
        </Grid>
        <Grid item xs={12} textAlign="end" pt="16px">
          <WebinarModal />
        </Grid>
      </SectionLayout>
      <SectionLayout name="hero-section" backgroundColor="trustBlue.main">
        <Grid item xs={12}>
          <Typography
            variant="p3"
            color="gray8.main"
            sx={{ fontSize: isMobile ? '0.75rem' : undefined }}
          >
            Projection Assumptions: Projections assume{' '}
            {(1 - DEFAULT_FUND_EQUITY_PERCENT_TARGET) * 100}% loan-to-value and
            fixed net operating income margins.{' '}
            {isMobile && (
              <>
                <br /> <br />
              </>
            )}
            Disclaimer: All content presented herein is purely informational and
            is not an offer to buy or sell securities or a solicitation of any
            offer to buy or sell securities. This is not personalized investment
            advice, nor should it be construed as financial, legal, or tax
            advice. A professional financial advisor, attorney, and/or tax
            professional should be consulted regarding your specific financial,
            legal, and/or tax situation. In considering the target performance
            information contained herein, prospective investors should bear in
            mind that targeted performance is not a guarantee, projection or
            prediction and is not necessarily indicative of future results.
            There can be no assurance that targeted returns will be met or that
            the Fund will be able to implement its investment strategy and
            investment approach or achieve its investment objectives. Actual
            gross and net returns for the Fund may vary significantly from the
            targeted returns set forth herein.
          </Typography>
        </Grid>
      </SectionLayout>
    </PageWrapper>
  )
}

export default RENTALPROPERTYCALCULATOR
